import * as React from "react";
import { useContext, useEffect } from "react";
import { Outlet, createBrowserRouter,useNavigate,useLocation  } from "react-router-dom";

import TimerPage from "../pages/TimerPage/TimerPage";
import Welcome from "../pages/Welcome/Welcome";
import Auth from "../pages/Auth/Auth";
import Signup from "../pages/SignUp/Signup.jsx";
import UnAuthenticated from "./UnAuthenticated";
import Nav from "../pages/Nav/Nav";
import "./routes.scss";
import Stats from "../pages/Stats/Stats";
import Solves from "../pages/Solves/Solves";
import Sessions from "../pages/Sessions/Sessions";
import Glossary from "../pages/Glossary/Glossary";
import Learnings from "../pages/Learnings/Learnings";
import Settings from "../pages/Settings/Settings";
import Profile from "../pages/Profile/Profile";
import Help from "../pages/Help/Help";
import { useIsMobile } from "../utils/functions/CustomHooks";
import MobileTop from "../components/Timer/Top Bar/MobileTop";
import { MainDiv } from "./StyledComponents.js";
import { ThemeContext } from "../context.js";
import { useState } from "react";
import ScrambleBox from "../components/Timer/Bottom Bar/ScrambleBox.jsx";
import ProtectedRoute from "./ProtectedRoute.js";
import ProfileFormPage from "../pages/ProfileForm/ProfileFormPage.jsx";
import { ReserveContext } from "../App.jsx";
import ProfileForm from "../components/profileForm/ProfileForm.jsx";

const App = () => {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { allStyles } = useContext(ThemeContext);
  const [focusMode, setFocusMode] = useState(false);
  const {  hideTimer, setHideTimer,
    solveSubmit, setSolveSubmit,inspRunningRef} = useContext(ReserveContext)
    let location = useLocation();
    // console.log(location.pathname);
  useEffect(() => {
    navigate("/timer");
  }, [navigate]);
  
  // useEffect(()=>{
  //   if(localStorage.getItem("profile")==="true")
  //     {
  //       navigate("/profile")
  //     }
  //   else if(localStorage.getItem("help")==="true")
  //     {
  //       navigate("/help");
  //     }
  //   // else navigate("/timer");
  // },[])


  return (
    <AppLayout 
    // className={ hideTimer==true && solveSubmit==false ? 'app-layout':null }
    // className='app-layout'
      isMobile={isMobile}
      allStyles={allStyles}
      focusMode={focusMode}
      setFocusMode={setFocusMode}
      hideTimer={hideTimer}
      solveSubmit={solveSubmit}
      inspRunningRef={inspRunningRef}
      location={location}

    />
  );
};


const AppLayout = ({ isMobile, allStyles,hideTimer,solveSubmit ,inspRunningRef,location}) => {

  return(
  <MainDiv theme={allStyles} className={`main${isMobile ? (location.pathname == '/profile' ||  location.pathname == '/help')? '-timer-mobile-full':`-mobile` : ""} ${location.pathname == '/timer' ? 'timer-page-mobile' : ''}`}>
    <div className={ hideTimer==true && solveSubmit==false && inspRunningRef.current == false ? '':null }></div>
    {isMobile ? <MobileTop /> : <Nav />}
    <Outlet />
  </MainDiv>
)};
const router = createBrowserRouter([
  {
    element: <App />,
    children: [
      {
        path: "/",
        element: <TimerPage />,
      },
      
      {
        path: "/timer",
        element: <TimerPage />,
      },
      {
        path: "/stats",
        element: <ProtectedRoute
          component={<Stats />}
        />,
      },
      {
        path: "/solves",
        element: <ProtectedRoute
        component={<Solves />}
      />,
      },
      {
        path: "/sessions",
        element: <ProtectedRoute
        component={<Sessions />}
      />,
      },
      {
        path: "/glossary",
        element: <Glossary />,
      },
      {
        path: "/learnings",
        element: <ProtectedRoute
        component={<Learnings />}
      />,
      },
      {
        path: "/help",
        element: <Help />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/profile",
        element: <ProtectedRoute
        component={<Profile />}
      />,
      },
    ],
  },
  {
    path: "/authenticate",
    element: (
      <UnAuthenticated>
        <Auth />
      </UnAuthenticated>
    ),
  },
  {
    path: "/signup",
    element: <Signup />,
  },
]);

export default router;
