import React, { useEffect, useRef, useState } from "react";
import { RouterProvider, useLocation } from "react-router";
import router from "./routes/routes";
import { initFirebase } from "./utils/firebase/firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";
import { setupInterceptor } from "./apis/interceptor";
import { fetchAndSave } from "./utils/functions/fetchAndSave.ts";
import { useImmer } from "use-immer";
import { createContext } from "react";
import "./assets/styles/global.scss";
import Auth from "./pages/Auth/Auth";
import { handleGoogleRedirect } from "./utils/firebase/googleAuth";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "./components/LoaderScreen/Loader.jsx";
import { driver } from "driver.js";
import "driver.js/dist/driver.css";
import { createSession } from "./apis/sessions.js";
import './App.scss'
import { useIsMobile } from "./utils/functions/CustomHooks.js";
export const ReserveContext = createContext(null);

const App = () => {
  const [reserve, updateReserve] = useImmer(null);
  const [isFocus, setisFocus] = useState(false);
  const [ManualTime, setManualTime] = useState(false);
  const [ManualEntry, setManualEntry] = useState(false);
  const [TimerDisplay, setTimerDisplay] = useState(false);
  const [Inspection, setInspection] = useState(true);
  const [VoiceAlert, setVoiceAlert] = useState(false);
  const [DeleteSolve, setDeleteSolve] = useState(false);
  const [DeleteSolveModal, setDeleteSolveModal] = useState(false);
  const [TimerFormat, setTimerFormat] = useState("ss.XX");
  const [scrambleFontSize, setScrambleFontSize] = useState(22);
  const [TimerFontSize, setTimerFontSize] = useState(50);
  const [load, setLoad] = useState(false);
  const [showFooter, setShowFooter] = useState(false);
  const [dispCNS, setDispCNS] = useState(false);
  const [matrixDropdown, setmatrixDropdown] = useState(false);
  const [overlay, Setoverlay] = useState(false);
  const [hideTimer, setHideTimer] = useState(false)
  const [solveSubmit, setSolveSubmit] = useState(false)
  const inspIntRef = useRef(null);
  const inspRunningRef = useRef(false);
  const [addClass, setAddClass] = useState(false)
  const [footerBlip, setFooterBlip] = useState(false)
  const [importModal, setimportModal] = useState(false)
  const [importModalUpcube, setimportModalUpcube] = useState(false)
  const [ProfileMobile, setProfileMobile] = useState(false)
  const [helpmobileform, sethelpmobileform] = useState(false)
  const [guide, setguide] = useState(false)

  // sessions
  let cubeType = localStorage.getItem("cubeType") || "222";
    
    // let currentSession = localStorage.getItem('current_session');
    // currentSession = JSON.parse(currentSession)

  // solve modal states
  const [solveModal, setSolveModal] = useState(false)
  const [solveModalVlue, setsolveModalVlue] = useState({
    scramble: '',
    date: '',
    cubetype: '',
    plus2Auto: '',
    plus2Manual: '',
    dnfManual: '',
    dnfAuto: '',
    solveTime: ''
  })
  const [solveId, setSolveId] = useState('')
  const [CurrentSolve, setCurrentSolve] = useState('')

  const isMobile = useIsMobile()

  useEffect(() => {
    let fontSize = localStorage.getItem("scrambleFontSize");

    setScrambleFontSize(fontSize ? Number(fontSize) : 22);
    let timerFont = localStorage.getItem("timerFontSize");
    setTimerFontSize(timerFont ? Number(timerFont) : 50);
  }, [scrambleFontSize, TimerFontSize]);

  const app = initFirebase();
  const [user, loading] = useAuthState(getAuth());
  const auth = getAuth(app);
 
  setupInterceptor(user);

  const dataSetter = async () => {
    let reserve = await fetchAndSave();
    updateReserve(reserve);
  };

  const handleCreateSession = async (body) => {

    await createSession(body)
      .then(async (data) => {
        setDispCNS(false);
        let newSess = data.session;
        await updateReserve((reserve) => {
          if (!reserve.sessions) reserve.sessions = [];
          reserve.sessions.push(newSess);
        });
        await handleOptionClick(newSess);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //       // setLoad(true);
  //       // await handleGoogleRedirect(auth, updateReserve, setLoad);
  //   };

  //   fetchData();
  // }, []);

  // storing settings options to local storage
  useEffect(() => {
    const initializeStateFromLocalStorage = () => {
      const manualTimeFromLocalStorage = localStorage.getItem("manualTime");
      if (manualTimeFromLocalStorage !== null) {
        setManualTime(manualTimeFromLocalStorage === "true");
      }

      const timerDisplayFromLocalStorage = localStorage.getItem("timerDisplay");
      if (timerDisplayFromLocalStorage !== null) {
        setTimerDisplay(timerDisplayFromLocalStorage === "true");
      }

      const inspectionFromLocalStorage = localStorage.getItem("inspection");
      if (inspectionFromLocalStorage !== null) {
        setInspection(inspectionFromLocalStorage === "true");
      }

      const voiceAlertFromLocalStorage = localStorage.getItem("voiceAlert");
      if (voiceAlertFromLocalStorage !== null) {
        setVoiceAlert(voiceAlertFromLocalStorage === "true");
      }

      const deleteSolveFromLocalStorage = localStorage.getItem("deleteSolve");
      if (deleteSolveFromLocalStorage !== null) {
        setDeleteSolve(deleteSolveFromLocalStorage === "true");
      }

      const scrambleFontSizeFromLocalStorage =
        localStorage.getItem("scrambleFontSize");
      if (scrambleFontSizeFromLocalStorage !== null) {
        setScrambleFontSize(Number(scrambleFontSizeFromLocalStorage));
      }

      const timerFontSizeFromLocalStorage =
        localStorage.getItem("timerFontSize");
      if (timerFontSizeFromLocalStorage !== null) {
        setTimerFontSize(Number(timerFontSizeFromLocalStorage));
      }
    };

    initializeStateFromLocalStorage();
    let verificationCancelCount = localStorage.getItem("verificationCancelCount");
    if(!verificationCancelCount){
      localStorage.setItem("verificationCancelCount", 0)
    }
  }, []);

  
  useEffect(() => {
    // const isNewUser = Number(localStorage.getItem('isNewUser'));

      if ((reserve && reserve.sessions === null && !guide)) {
        handleCreateSession({ name: 'MyFirstSession' });
        setguide(true);
      }
    
    
  }, [reserve ,guide]);

   const driverObj = driver({
    showProgress: true,
    allowClose: false,
    allowKeyboardControl: true,
    onPopoverRender: (popover, { config, state }) => {

      document.body.addEventListener('keydown', function (e) {

        if (e.keyCode == 32) {
          if (state.activeStep.popover.progressText[0] == '4') {
            driverObj.drive(4);

          }
          if (state.activeStep.popover.progressText[0] == '5') {
            driverObj.drive(4);
          }

        }
        if (state.activeStep.popover.progressText[0] == '1') {
          setAddClass(true)
        }
        else {
          setAddClass(false)
        }
      });
    },
    popoverClass: `driverjs-theme space-theme`,
    steps: [
      {
        popover: {
          title: "Welcome to UpCube!",
          description: "Professional Cube Training Program designed for cubers. Let's get solving!",
          popoverClass: 'my-custom-popover-class'
        },
      },
      {
        element: "#session-dropdown",
        popover: {
          title: "Create a Session",
          description: "Click here to create a new session.",
          side: "bottom",
          align: 'end'
        },

      },
      {
        element: "#select-cubetype",
        popover: {
          title: "Select an Event",
          description: "You can select an event from here.",
        },
      },

      {
        element: "#start-inspection",
        popover: {
          title: `${isMobile ? "Tap on the screen to 'start' the inspection" : "Press the 'Space' button to start the inspection"}`,
          description: `${isMobile ? "Tap on the screen to 'start' the inspection" : "By pressing the 'Space' button you can start the inspection."}`,
          // "By pressing the 'Space' button you can start the inspection.",
        },
      },

      {
        element: "#start-solving",
        popover: {
          title: `${isMobile ? "Long Tap to 'start' the solve" : "Long press the 'Space' button to start the solve timer"}`,
          description: `${isMobile ? "Long Tap to 'start' the solve" : "By pressing the 'Space' button for 2 seconds, you can start solving cube."}`,
          // "By pressing the 'Space' button for seconds, you can start solving cube.",
          side: "bottom",
          align: 'end'
        },
      },

      {
        popover: {
          title: "Happy Solving",
          description:
            "And that is all, go ahead and start solving cube in our application.",
        },
      },
    ],

    onDestroyed: () => {
      localStorage.setItem("isNewUser", 0);
      setguide(false);
    }
  });

  useEffect(() => {
    if (guide) {
      driverObj.drive();
    }
  }, [guide]);


  if (load) {
    return <Loader />;
  }

  if (!user) {
    return <Auth setguide={setguide} />;
  }

  if (!reserve && user) {
    dataSetter();
    return <Loader />;
  }

  if (!reserve && loading) {
    return <Loader />;
  }

  const handleOptionClick = (option) => {
    localStorage.setItem('current_session', JSON.stringify(option))
  };

  

 
  

  return (
    <ReserveContext.Provider
      value={{
        reserve, updateReserve,
        isFocus, setisFocus,
        scrambleFontSize, setScrambleFontSize,
        TimerFontSize, setTimerFontSize,
        ManualEntry, setManualEntry,
        ManualTime, setManualTime,
        TimerDisplay, setTimerDisplay,
        DeleteSolve, setDeleteSolve,
        DeleteSolveModal, setDeleteSolveModal,
        TimerFormat, setTimerFormat,
        showFooter, setShowFooter,
        Inspection, setInspection,
        VoiceAlert, setVoiceAlert,
        dispCNS, setDispCNS,
        matrixDropdown, setmatrixDropdown,
        solveModal, setSolveModal,
        solveModalVlue, setsolveModalVlue,
        CurrentSolve, setCurrentSolve,
        solveId, setSolveId,
        overlay, Setoverlay,
        hideTimer, setHideTimer,
        solveSubmit, setSolveSubmit,
        inspIntRef, inspRunningRef,
        footerBlip, setFooterBlip,
        importModal, setimportModal,
        importModalUpcube, setimportModalUpcube,
        ProfileMobile, setProfileMobile,
        helpmobileform, sethelpmobileform,
        guide, setguide,
        app
      }}
    >

      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable
        pauseOnHover={false}
        theme="dark"
      />
      <RouterProvider router={router} />
    </ReserveContext.Provider>
  );
};

export default App;
