import { getAllSolves } from "../../apis/solves";
import { getAllSessions } from "../../apis/sessions";
import { getUser } from "../../apis/users";
import "driver.js/dist/driver.css";
import { toast } from "react-toastify";
import axios from "axios";

const profileData = async(data)=>{
    
  const formData = {
    Uid: data.uid,
    Email: data.email !=="null" ? data.email : `upcube@dev_${Math.random().toString(36).substring(2, 8)}`,
    Username: `testuser_${Math.random().toString(36).substring(2, 8)}`,
    Gender: "other",
    Name: data.name !=="null" ? data.name : `GuestUser_${Math.random().toString(36).substring(2, 8)}`,
    Dob: "1989-12-31T18:30:00.000Z",
    Country: "country",
    State: "state",
    City: 'city',
    Mobile: data.mobile || '1234567890',
    Bio: '',
    ProfilePic: '',
    CoverPic: '',
    JoinDate: new Date().toISOString(),
    Social: {
      Youtube: '',
      Instagram: '',
      Twitter: '',
      Reddit: '',
      WCA:  '',
      Facebook: '',
    },
    CubeSpecifies: {
      Method333: '',
      Goal333: '',
      MainCube: '',
      FavouriteEvent: '',
    }
  };
  try {
    const response = await axios.post('https://upcube.el.r.appspot.com/api/v1/auth/signup', formData, {
      headers: {
        'Content-Type': 'application/json',
        // Add any additional headers if required
      }
    });
    if (!response) {
      toast.error('Failed to create user .');
    } else {
      window.location.href = '/timer'
      toast.success('Account created successfully.');
    }

  } catch (error) {
    toast.error('Failed to create user .');
    console.error('Error submitting form:', error);
    // Handle error (e.g., show error message)
  }
}

export const fetchAndSave = async () => {
  
  let solves = (await getAllSolves()).solves || [];
  let sessions = (await getAllSessions()).sessions;
  // console.log(sessions && sessions.length == 0)
  if ((sessions && sessions.length == 0) == null) {
    // driverObj.drive();
  }

  let user = await getUser();

  if(!user)
    {
      const uid = localStorage.getItem('Uid');
      const email = localStorage.getItem('email');
      const name = localStorage.getItem('name');
      const mobile = localStorage.getItem('mobile');
      const data={uid:uid,email:email,name:name,mobile:mobile};
      await profileData(data);
    }
    localStorage.removeItem('Uid');
    localStorage.removeItem('email');
    localStorage.removeItem('name');
    localStorage.removeItem('mobile');
  return {
    
    solves: solves,
    sessions: sessions,
    user: user,
  };
};
