import React, { useCallback, useContext, useEffect } from 'react';
import Modal from '@mui/material/Modal';
import axios from 'axios';
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import { ButtonInActive } from '../Setting/StyledComponent';
import { ThemeContext } from '../../context';
import { getAuth, RecaptchaVerifier, linkWithPhoneNumber } from "firebase/auth";
// import { initFirebase } from '../../utils/firebase/firebase';
import './PhoneNumber.scss'


// import firebase from 'firebase/app';
import 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';
// import { Box } from '../Timer/styledComponents';
import { toast } from 'react-toastify';
import { ReserveContext } from '../../App';
// import { useIsMobile } from '../../utils/functions/CustomHooks';
import { IoMdClose } from 'react-icons/io';
import { urls } from '../../apis/urls';

// const auth = getAuth();
function PhoneNumber({isPhone}) {
    
    //    const app = initFirebase();
    // const isMobile = useIsMobile();
    const [open, setOpen] = React.useState(isPhone);
    const [phone, setPhone] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [sent, setSent] = React.useState(false);
    const {reserve: {user: userDbData}} = useContext(ReserveContext);
    const [canCancelPhoneverification, setCanCancelPhoneverification] = React.useState(true);
    const [cancelCount, setCancelCount] = React.useState(0);
    // const [user, setUser] = useState(null);
    // window.recaptchaVerifier =;
    // console.log("phone: ", phone);
    const { allStyles, themeName } = useContext(ThemeContext);

    // const sendOtp = async() => {
    //     const appVerifier =  new RecaptchaVerifier("recaptcha-container",  {}, auth);
    //     let phoneNumber = "+91" +phone
    //     const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
    //     window.confirmationResult = confirmationResult;
    //     console.log("confirmationResult", confirmationResult);
       
    // }

    useEffect(() => {
        const verificationCancelCount = localStorage.getItem('verificationCancelCount');
        setCancelCount(verificationCancelCount);
        if (verificationCancelCount >= 5) {
            setCanCancelPhoneverification(false);
        }
    }, []);

    // useEffect(() => {
    //     const auth = getAuth();
    //     const userData = auth.currentUser;
    //     setUser(userData);
    //     const appVerifier = new RecaptchaVerifier('recaptcha-container', {
    //         size: 'normal', // or 'normal' for visible reCAPTCHA
    //     }, auth);
    //     window.recaptchaVerifier = appVerifier;
    // }, []);

    const verifyOtp = async() => {
        try {
            const result = await window.confirmationResult.confirm(otp);
            // console.log(result);
            console.log("Phone number linked:", result.user);
            const response = await axios.put(`${urls.updateuser}`, {...userDbData, mobile: phone});
            toast.success("Phone number linked", { autoClose: 1000, className: 'copy-toast' });
            
            setOpen(false);
        } catch (error) {
            console.log(" Error verifying phone number: ",error);
             if (error.code === "auth/invalid-verification-code") {
                    // alert("Invalid OTP. Please enter the correct code.");
                    toast.error("Invalid OTP. Please enter the correct code.", { autoClose: 1000, className: 'copy-toast' })
                } else if (error.code === "auth/code-expired") {
                    // alert("OTP has expired. Please request a new one.");
                    toast.error("OTP has expired. Please request a new one.", { autoClose: 1000, className: 'copy-toast' })
                } else if(error.code === "auth/account-exists-with-different-credential"){
                    toast.error("Phone number already exists with different credential.", { autoClose: 2000, className: 'copy-toast' })
                } else {
                    toast.error("Error verifying phone number", { autoClose: 1000, className: 'copy-toast' })
                }
              
        } finally {
            // window.confirmationResult = null;
            // setTimeout(() => {
            //     window.location.reload();
            // }, 2000)
        }
    }


    // const [user, loading] = useAuthState(getAuth());



    // const auth = getAuth(app);
    // console.log("phone: ", phone);
    const verifyPhoneNumber = useCallback(async (phone) => {
        let phoneNumber = "+91" + phone
        const auth = getAuth();
        const user = auth.currentUser;
        const appVerifier = new RecaptchaVerifier('recaptcha-container', {
            size: 'normal', // or 'normal' for visible reCAPTCHA
        }, auth);

        try {

            const confirmationResult = await linkWithPhoneNumber(user, phoneNumber, appVerifier);
            window.confirmationResult = confirmationResult;
            setSent(true);
            
            toast.success("OTP Sent Successfully", { autoClose: 1000, className: 'copy-toast' });
            
            // const verificationCode = window.prompt('Enter the verification code:');
            // const result = await confirmationResult.confirm(verificationCode);
            // console.log("Phone number linked:", result.user);
        } catch (error) {
            console.error("Error verifying phone number:", error);
            toast.error("Error verifying phone number", { autoClose: 1000, className: 'copy-toast' });
        }
    }, []);

  return (
    <div>
        <Modal open={open} onClose={() => setOpen(true)}>
            <div className='modal-wrapper'>
                {canCancelPhoneverification && <div style={{ position: 'relative', width: '100%' }}>
                    <div style={{ position: '' }} onClick={() => {
                        setOpen(false);
                        setCancelCount(cancelCount + 1);
                        localStorage.setItem('verificationCancelCount', Number(cancelCount) + 1);
                        localStorage.setItem('verify_modal', 0);
                    }}>
                        <button
                            className="close-button" aria-label="Close"
                            style={{ right: '-15px', top: '-10px', backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null', width: "auto" }}
                        >
                            <IoMdClose style={{ width: '20px' }} />
                        </button>
                    </div>
                 </div>}
                <h3 style={{ textAlign: 'center' }}>Verify Contact Information <span style={{ color: 'red', display: 'block' }}>{!canCancelPhoneverification && cancelCount > 0 && "Required"}</span></h3>
                {!sent && <>
                <input
                    // country={'in'}
                    value={phone}
                    placeholder='Enter phone number'
                    onChange={(e) => setPhone(e.target.value)}
                />
                <ButtonInActive className='btn-otp' onClick={() =>verifyPhoneNumber(phone)} theme={allStyles} >
                    Send OTP
                </ButtonInActive>
                <RecaptchaContainer />
                </>}

                {sent && <>
                <input value={otp} placeholder='Enter OTP' onChange={(e) => setOtp(e.target.value)}  />
                <ButtonInActive theme={allStyles} className='btn-otp' onClick={verifyOtp} >Verify OTP</ButtonInActive>
                </>}
               
            </div>
        </Modal>
    </div>
  )
}

export default React.memo(PhoneNumber);

const RecaptchaContainer = React.memo(() => (
  <div id="recaptcha-container" />
));

