import React, { useContext, useRef } from 'react'
import { Heading, ProfileModal, FocusBox, TextColor } from './StyledComponent'
import { IoMdClose } from 'react-icons/io'
import './SolveModal.scss'
import { MdSaveAlt } from 'react-icons/md'
import { IoIosCube } from 'react-icons/io'
import { extractCentiseconds, extractFormatHours, extractMinutes, extractSeconds } from '../../Timer/Timer Section/TimerLogic'
import { FaRegCopy } from "react-icons/fa";
import { toast } from 'react-toastify'
import * as htmlToImage from 'html-to-image';
import {
    get222imgmatrix,
    get333imgmatrix,
    get444imgmatrix,
    get555imgmatrix,
    get666imgmatrix,
    get777imgmatrix,
} from "wca-scramble-to-matrix";
import { useIsMobile } from '../../../utils/functions/CustomHooks'
import { useLocation } from 'react-router-dom'
import Cube from '../../cube/Cube'
import Svg from '../../cube/Svg'
import { ThemeContext } from '../../../context'
const SolveModal = ({
    allStyles,
    setSolveModal,
    solveModalVlue,
    handleUpdateSolveForPlus2,
    handleUpdateSolveDNF,
    CurrentSolve,
    setDeleteSolveModal,
    setSolveId,
    handleDeleteSolve,
    DeleteSolve
}) => {
    let date, day, month, year, time;
    let solve_date;
    if(solveModalVlue.date.includes("Z")){
        solve_date = new Date(solveModalVlue.date).toString()
    } else {
        solve_date = solveModalVlue.date
    }

    day = solve_date.split(' ')[0]
    month = solve_date.split(' ')[1]
    date = solve_date.split(' ')[2]
    year = solve_date.split(' ')[3]
    time = solve_date.split(' ')[4]


    const cubeRef = useRef();
    const location = useLocation();
    const { themeName } = useContext(ThemeContext);

    console.log("solveModalVlue", solveModalVlue)

    function displaySingleDigitTime(dynamicTime) {

        const hours = dynamicTime.split(':')[0];
        const minutes = dynamicTime.split(':')[1];
        const period = hours >= 12 ? 'PM' : 'AM';

        const displayHour = hours % 12 || 12; // Handle midnight (0) as 12 AM

        return `${displayHour}:${minutes} ${period}`;
    }

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(solveModalVlue.scramble);
            //   setIsCopied(true);
            toast.success("Copied to Clipboard .", { autoClose: 1000, className: 'copy-toast' })
        } catch (error) {
            toast.error("Failed to copy text to clipboard.", { autoClose: 1000, className: 'copy-toast' });
        }
    };

    const saveImage = () => {
        htmlToImage.toJpeg(cubeRef.current)
            .then(function (dataUrl) {
                var link = document.createElement('a');
                link.download = 'solve_image.jpg';
                link.href = dataUrl;
                link.click();
            });

        toast.success("Image saved successfully.", { autoClose: 1000, className: 'copy-toast' })
    };

    const returnMatrixCubeType = (input) => {
        switch (input) {
            case "222":
                return "2x2";
            case "333":
                return "3x3";
            case "333OH":
                return "3x3OH";
            case "333BF":
                return "3x3BF";
            case "444":
                return "4x4";
            case "555":
                return "5x5";
            case "666":
                return "6x6";
            case "777":
                return "7x7";
            case "minx":
                return "megaminx";
            default:
                return input;
        }
    };
    const isMobile = useIsMobile()
    const router = useLocation()
    let matrix;
    switch (solveModalVlue.cubetype) {
        case "222":
            matrix = get222imgmatrix(solveModalVlue.scramble);
            break;
        case "333":

            matrix = get333imgmatrix(solveModalVlue.scramble);
            break;
        case "333OH":

            matrix = get333imgmatrix(solveModalVlue.scramble);
            break;
        case "333BF":

            matrix = get333imgmatrix(solveModalVlue.scramble);
            break;
        case "444":

            matrix = get444imgmatrix(solveModalVlue.scramble);
            break;
        case "444BF":

            matrix = get444imgmatrix(solveModalVlue.scramble);
            break;
        case "555":

            matrix = get555imgmatrix(solveModalVlue.scramble);
            break;
        case "555BF":

            matrix = get555imgmatrix(solveModalVlue.scramble);
            break;
        case "666":

            matrix = get666imgmatrix(solveModalVlue.scramble);
            break;
        case "777":

            matrix = get777imgmatrix(solveModalVlue.scramble);
            break;

        default:
            break;
    }

    const scrambled_cube = solveModalVlue.cubetype === 'skewb' ||
        solveModalVlue.cubetype === 'pyra' ||
        solveModalVlue.cubetype === 'minx' ||
        solveModalVlue.cubetype === 'clock' ||
        solveModalVlue.cubetype === 'sq1' ? true : false

    return (
        <div className={`profile-modal`} style={{ color: "white", height: `${isMobile ? '100%' : ''}` }} >

            <ProfileModal theme={allStyles} className='profile-modal-container' style={{ width : `${isMobile ? '100%' : ''}` }} onClick={(e) => { e.stopPropagation(); }}>

                {/* close button */}
                {/* {!isMobile ? (<div style={{ position: 'relative', cursor: 'pointer', zIndex: '1000' }} onClick={() => setSolveModal(false)}>
                    <button
                        className="close-button" aria-label="Close"
                        style={{ right: '-5px' }}
                    >
                        <IoMdClose style={{ width: '15px', height: '25px' }} />
                    </button>
                </div>) : (<div style={{ position: 'relative', zIndex: '1000' }} onClick={() => setSolveModal(false)}>
                    <button
                        className="close-button" aria-label="Close"
                        style={{ right: '-14px', top: '5px' }}
                    >
                        <IoMdClose style={{ width: '20px' }} />
                    </button>
                </div>)} */}

                {/* close button */}
                <div className="solve-close-button" style={{ position: 'relative', zIndex: '1000' }} >
                    {/* <div 
                         aria-label="Close" style={{ top: '18px' }}
                    > */}
                        {/* <IoMdClose  style={{ color:themeName==='light'?"black":"white" }} /> */}
                    {/* </div> */}
                    <button
                        className="close-button" aria-label="Close"
                        style={{right: '-5px', position: "static", backgroundColor: themeName === 'light' ? 'rgb(180 180 180)' : 'null'}}
                        onClick={() => { setSolveModal(false) }}
                    >
                        <IoMdClose style={{width: '20px'}} />
                    </button>
                </div>

                {/* header */}
                <div className='solve-modal-header'>

                    {/* Share Link */}
                    <FocusBox theme={allStyles} className='focus-button solve-modal-save-solves' onClick={saveImage}>
                        Save Solves <MdSaveAlt style={{ marginLeft: '0.5rem' }} />
                    </FocusBox>

                    {/* Delete button */}
                    <FocusBox theme={allStyles} className='focus-button solve-modal-delete-button'
                        onClick={() => {
                            console.log("cuurentSolve", CurrentSolve)
                            console.log("DeleteSolve", DeleteSolve)
                            if (DeleteSolve) {
                                setDeleteSolveModal(true)
                                setSolveId(CurrentSolve.solveId)
                                setSolveModal(false)
                            } else {
                                handleDeleteSolve(CurrentSolve.solveId)
                                setSolveModal(false)
                            }
                        }}
                    >
                        Delete
                    </FocusBox>

                </div>

                <div ref={cubeRef}>

                    {/* Display values */}
                    <div className='solve-modal-box'>

                        {/* Solve timer */}
                        <Heading theme={allStyles} className="solve-modal-timer">
                            {solveModalVlue.dnfAuto || solveModalVlue.dnfManual ? null : extractFormatHours(solveModalVlue.solveTime)}
                            {solveModalVlue.dnfAuto || solveModalVlue.dnfManual ? null : extractMinutes(solveModalVlue.solveTime).split(":")[0] > 0 ? extractMinutes(solveModalVlue.solveTime).split(":")[0] % 60 + ':' : null}
                            {solveModalVlue.dnfAuto || solveModalVlue.dnfManual ? "DNF" : (solveModalVlue.plus2Auto || solveModalVlue.plus2Manual ? `${extractSeconds(solveModalVlue.solveTime + 2000)}` : extractSeconds(solveModalVlue.solveTime))}
                            <span style={{fontSize: "30px"}} >{solveModalVlue.dnfAuto || solveModalVlue.dnfManual ? null : extractCentiseconds(solveModalVlue.solveTime)}</span>
                        </Heading>

                        {/* Solve date */}
                        <Heading theme={allStyles} className='solve-modal-date'>
                            {day} {month} {date}, {year} {displaySingleDigitTime(time)}
                        </Heading>

                        {/* Solve methods */}
                        <div className='modal-solve-methods'>

                            <FocusBox theme={allStyles}
                                className={`modal-solve-cubetype `}
                            >
                                {returnMatrixCubeType(solveModalVlue.cubetype)}
                                <IoIosCube style={{ color: themeName === 'light' ? "black" : "white", marginLeft: '0.15rem' }} />
                            </FocusBox>

                            {/* <div className='modal-solve-plus2'> */}
                            <FocusBox theme={allStyles}
                                className={`modal-solve-plus2 ${solveModalVlue.plus2Auto || solveModalVlue.plus2Manual
                                    ? "plus2DNF-enable"
                                    : null
                                    }`}
                                onClick={() => {
                                    handleUpdateSolveForPlus2(CurrentSolve);
                                }}
                            >
                                +2
                            </FocusBox>
                            {/* </div> */}

                            <FocusBox theme={allStyles}
                                className={`modal-solve-dnf ${solveModalVlue.dnfManual || solveModalVlue.dnfAuto ? "plus2DNF-enable" : null}`}
                                onClick={() => {
                                    handleUpdateSolveDNF(CurrentSolve);
                                }}
                            >
                                DNF
                            </FocusBox>
                        </div>

                    </div>

                    {/* Scramble button */}
                    <div className='solve-modal-buttons'>

                        <FocusBox theme={allStyles} className='focus-button solve-modal-scramble-button'>
                            Scramble
                        </FocusBox>

                    </div>

                    {/* Scramble Image */}
                    <div className='solve-scramble-image'>
                        <div className="cube_class" ref={cubeRef}
                            style={{
                                position: `${router.pathname === '/sessions' || router.pathname === '/solves' || router.pathname === '/timer' ? 'relative' : ''}`,
                                bottom: `${router.pathname === '/sessions' || router.pathname === '/solves' || router.pathname === '/timer' ? isMobile ? '0rem' : scrambled_cube ? '0' : '3rem' : ''}`,
                                maxWidth: `${router.pathname === '/sessions' || router.pathname === '/solves' || router.pathname === '/timer' ? '280px' : '400px'}`,
                            }}
                        >
                            {
                                scrambled_cube ?
                                    <div className="solvemodal-svg">

                                        <Svg cubeType={solveModalVlue.cubetype} scramble={solveModalVlue.scramble} />
                                    </div>
                                    :
                                    <Cube matrix={matrix} />
                            }
                        </div>
                        {/* <ScrambledCube scramble={solveModalVlue.scramble} cubeType={solveModalVlue.cubetype}  /> */}
                    </div>

                    {/* Scramble Value */}
                    <div className='solve-modal-scramble'>
                        <div className='solve-modal-scramble-value'>
                            <TextColor
                                theme={allStyles}
                                style={{ fontSize: `${solveModalVlue.cubetype === 'minx' || solveModalVlue.cubetype === '777' ? '12px' : '18px'}` }}
                            >
                                {solveModalVlue.scramble}
                            </TextColor>
                        </div>

                    </div>

                </div>

                {/* Copy Scramble */}
                <div className='solve-modal-scramble' style={{ marginTop: '0' }}>

                    <FocusBox theme={allStyles} className='focus-button' onClick={copyToClipboard}>
                        Copy Scramble <FaRegCopy style={{ marginLeft: '0.5rem' }} />
                    </FocusBox>

                </div>

            </ProfileModal>
        </div>
    )
}

export default SolveModal