import React, { useCallback, useContext, useEffect, useState } from 'react'
import './Profile.scss'
import { Heading, InputTextColor, FocusBox, Form, TextColor } from './StyledComponent'
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
    CitySelect,
    CountrySelect,
    StateSelect,
    GetCountries,
    GetState,
    GetCity,
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import LogoutModal from './LogoutModal';
import { FaCalendarAlt } from "react-icons/fa";
import { ThemeContext } from '../../context';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ReserveContext } from '../../App';
import ProfileMobileModal from '../Modals/ProfileModal/ProfileModal';
import animation from "../../assets/images/animation_3.gif"
import { useIsMobile } from '../../utils/functions/CustomHooks';
const ProfileComponent = ({ allStyles }) => {

    const { themeName } = useContext(ThemeContext);
    const { ProfileMobile, setProfileMobile } = useContext(ReserveContext);
    const isMobile = useIsMobile();
    const [userData, setUserData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        gender: '',
        wcaid: '',
        DOB: '',
        country: null,
        state: null,
        city: null,
        // mobile: '',
    });
    const [logoutModal, setLogoutModal] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleChange = useCallback((e) => {
        console.log("updating")
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    }, []);

    const handleDateChange = useCallback((date) => {
        setFormData(prev => ({ ...prev, DOB: date }));
    }, []);

    const handleCountryChange = useCallback((country) => {
        setFormData(prev => ({
            ...prev,
            country,
            state: null,
            city: null,
        }));
    }, []);

    const handleStateChange = useCallback((state) => {
        setFormData(prev => ({
            ...prev,
            state,
            city: null,
        }));
    }, []);

    const handleCityChange = useCallback((city) => {
        setFormData(prev => ({ ...prev, city }));
    }, []);

    const fetchUserData = useCallback(async () => {
        try {
            const response = await axios.get('https://upcube.el.r.appspot.com/api/v1/auth/user');
            localStorage.setItem("profile", false);

            if (!response) {
                toast.error('Failed to fetch user data.');
                return;
            }

            const { user } = response.data;
            setUserData(user);

            const countryList = await GetCountries();
            const selectedCountry = countryList.find(item => item.name === (user.country === 'country' ? "" : user.country)) || null;

            let selectedState = null;
            let selectedCity = null;

            if (selectedCountry) {
                const stateList = await GetState(selectedCountry.id);
                selectedState = stateList.find(item => item.name === user.state) || null;

                if (selectedState) {
                    const cityList = await GetCity(selectedCountry.id, selectedState.id);
                    selectedCity = cityList.find(item => item.name === user.city) || null;
                }
            }

            setFormData({
                name: user.name || '',
                // gender: user.gender !== 'other' ? user.gender : '',
                gender: user.gender,
                wcaid: user.social.wca || '',
                DOB: user.dob === '1989-12-31T18:30:00.000Z' ? "" : new Date(user.dob),
                country: selectedCountry,
                state: selectedState,
                city: selectedCity,
                // mobile: user.mobile !== '1234567890' ? user.mobile : '',
            });

        } catch (error) {
            console.error('Error fetching user data:', error);
            toast.error('Failed to fetch user data.');
        }
    }, []);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        // if (!formData.state) {
        //     toast.error('Please select proper State & City');
        //     return;
        // }
        // if (!formData.city) {
        //     toast.error("Please select proper City");
        //     return;
        // }

        const updatedUserData = {
            ...userData,
            ...formData,
            country: formData.country?.name || userData.country,
            state: formData.state?.name || userData.state,
            city: formData.city?.name || userData.city,
            DOB: formData.DOB || new Date(userData.dob),
            social: {
                ...userData.social,
                wca: formData.wcaid
            }
        };
        try {
            const response = await axios.put('https://upcube.el.r.appspot.com/api/v1/auth/update', updatedUserData);
            if (response) {
                toast.success('Saved changes successfully');
                setFormSubmitted(true);
                setTimeout(() => setFormSubmitted(false), 3000);
            } else {
                toast.error('Failed to save changes.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            toast.error('Failed to Save Changes.');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
    };

    if (!userData) {
        return (
            <div className='loader-mobile' style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100vw',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
            }}>
                <img src={animation} alt="Loading.." />
            </div>
        );
    }
    const preventFocus = (e) => e.preventDefault();
    return (
        <>  
            
            {
                userData ? (
                    <>
                    <div className='profile-container'>

                    {ProfileMobile && <ProfileMobileModal formData={formData} setFormData={setFormData} />}

                    {logoutModal && <LogoutModal allStyles={allStyles} setLogoutModal={setLogoutModal} />}

                    <div className='profile-header'>

                        {/* stats heading */}

                        <Heading theme={allStyles} className='profile-heading'>
                            Profile
                        </Heading>

                        {/* stats buttons */}

                        <div className='profile-btn'>

                            <FocusBox theme={allStyles} className='focus-button' onClick={() => { setLogoutModal(true) }}>
                                Log Out
                            </FocusBox>

                        </div>

                    </div>

                    {!isMobile && <Form theme={allStyles} className="profile-form-padding">
                        <div className="profile-form">
                            <div className="profile-form-content">
                                {(userData.email && !userData.email.includes("upcube@dev")) && <div className='form-div'>
                                    <InputTextColor theme={allStyles} htmlFor="email">Email</InputTextColor>
                                    <input
                                        type="text"
                                        value={!userData.email.includes("upcube@dev") ? userData.email : ''}
                                        name='email'
                                        id='email'
                                        onMouseDown={preventFocus}
                                        onTouchStart={preventFocus}
                                        class="disabled-input"
                                    />
                                </div>}
                                {userData.mobile && userData.mobile !== '1234567890' && <div className='form-div'>
                                    <InputTextColor theme={allStyles} htmlFor="mobile">Mobile</InputTextColor>
                                    <input
                                        type="text"
                                        value={userData.mobile !== '1234567890' ? userData.mobile : ''}
                                        name='mobile'
                                        id='mobile'
                                        onMouseDown={preventFocus}
                                        onTouchStart={preventFocus}
                                        class="disabled-input"
                                    />
                                </div>}
                            </div>
                        </div>
                        
                    </Form>}
                    < br />
                    <Form theme={allStyles} className='profile-form-padding'>
                        <div className='profile-form'>

                            {/* desktop-form */}
                            <form onSubmit={handleSubmit} className='desktop-profile-form'>
                                <div className='profile-form-content'>
                                    {/* Name */}
                                    <div className='form-div'>
                                        <InputTextColor theme={allStyles} htmlFor="name">Name</InputTextColor>
                                        <input
                                            type="text"
                                            value={formData.name}
                                            onChange={handleChange}
                                            name='name'
                                            id='name'
                                            // required
                                        />
                                    </div>

                                    {/* Gender */}
                                    {/* <div className='form-div'>
                                        <InputTextColor theme={allStyles} htmlFor="gender">Gender</InputTextColor>
                                        <select
                                            value={formData.gender}
                                            onChange={handleChange}
                                            name='gender'
                                            id='gender'
                                            // required
                                        >
                                            <option value="">Select</option>
                                            <option value="male">Male</option>
                                            <option value="female">Female</option>
                                            <option value="other">Other</option>
                                        </select>
                                    </div> */}

                                    {/* WCA id */}
                                    <div className='form-div'>
                                        <InputTextColor theme={allStyles} htmlFor="wcaid">WCA id</InputTextColor>
                                        <input
                                            type="text"
                                            value={formData.wcaid}
                                            onChange={handleChange}
                                            name='wcaid'
                                            id='wcaid'
                                            placeholder='Enter WCA id'
                                        />
                                    </div>

                                    {/* Date of Birth */}
                                    <div className='form-div' style={{ position: 'relative' }}>
                                        <InputTextColor theme={allStyles} htmlFor="DOB">Date of Birth</InputTextColor>
                                        <FaCalendarAlt className='info-profile-calendar' />
                                        <Datetime
                                            value={formData.DOB}
                                            onChange={handleDateChange}
                                            timeFormat={false}
                                            dateFormat="DD-MM-YYYY"
                                            placeholderText="Select a date"
                                            inputProps={{ placeholder: "Select a date" }}
                                            className={`profile-date-picker${themeName === 'light' ? '' : ''}`}
                                        />
                                    </div>

                                    {/* Country */}
                                    <div className='form-div'>
                                        <InputTextColor theme={allStyles} htmlFor="country">Country</InputTextColor>
                                        <CountrySelect
                                            value={formData.country}
                                            defaultValue={formData.country}
                                            placeHolder='Country'
                                            onChange={handleCountryChange}
                                            className="profile-country-dropdown"
                                            // required
                                        />
                                    </div>

                                    {/* State */}
                                    <div className='form-div'>
                                        <InputTextColor theme={allStyles} htmlFor="state">State</InputTextColor>
                                        <StateSelect
                                        key={formData.country?.id}
                                            value={formData.state}
                                            defaultValue={formData.state}
                                            countryid={formData.country?.id}
                                            placeHolder='State'
                                            onChange={handleStateChange}
                                            className="profile-state-dropdown"
                                            // required
                                        />
                                    </div>

                                    {/* City */}
                                    <div className='form-div'>
                                        <InputTextColor theme={allStyles} htmlFor="city">City</InputTextColor>
                                        <CitySelect
                                            key={formData.state?.id}
                                            value={formData.city}
                                            defaultValue={formData.city}
                                            countryid={formData.country?.id}
                                            stateid={formData.state?.id}
                                            placeHolder="City"
                                            onChange={handleCityChange}
                                            className="profile-state-dropdown"
                                            // required
                                        />
                                    </div>

                                    {/* Mobile */}
                                    {/* <div className='form-div'>
                                        <InputTextColor theme={allStyles} htmlFor="mobile">Mobile Number</InputTextColor>
                                        <input
                                            // type="number"
                                            value={formData.mobile}
                                            onChange={handleChange}
                                            name='mobile'
                                            id='mobile'
                                            placeholder='Enter mobile no.'
                                            // required
                                        />
                                    </div> */}
                                </div>

                                <div className='profile-form-btn'>
                                    <FocusBox
                                        theme={allStyles}
                                        className='focus-button focus-btn-desktop'
                                        type='submit'
                                        style={{
                                            cursor: formSubmitted && 'not-allowed',
                                        }}
                                        disabled={formSubmitted}
                                    >
                                        Update Info
                                    </FocusBox>
                                </div>
                            </form>

                            {/* Mobile display data */}

                            <div className='profile-form-content-mobile'>
                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Name : {formData.name ? formData.name : " "}
                                </TextColor>

                                {/* <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Gender : {formData.gender ? formData.gender : ""}
                                </TextColor> */}

                                {userData.email && userData.email !== "upcube@dev" && <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Email : {!userData.email.includes("upcube@dev") ? userData.email : ""}
                                </TextColor>}

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    WCA id : {formData.wcaid ? formData.wcaid : ""}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Date of birth : {formData.DOB ? formatDate(formData.DOB) : ""}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Country : {formData.country ? formData.country.name : "India"}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    State : {formData.state ? formData.state.name : ""}
                                </TextColor>

                                <TextColor theme={allStyles} className='profile-data-mobile'>
                                    City : {formData.city ? formData.city.name : ""}
                                </TextColor>

                                {userData.mobile && userData.mobile !== "1234567890" && <TextColor theme={allStyles} className='profile-data-mobile'>
                                    Mobile Number : {userData.mobile !== "1234567890" ? userData.mobile : ""}
                                </TextColor>}

                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1rem' }}>
                                    <FocusBox
                                        theme={allStyles}
                                        className='focus-button focus-btn-mobile'
                                        type='submit'
                                        onClick={() => { setProfileMobile(!ProfileMobile) }}
                                    >
                                        Update Info
                                    </FocusBox>
                                </div>
                            </div>

                        </div>
                    </Form>

                </div> </>) : (<div className='loader-mobile' style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100vw',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                }}>
                    <img src={animation} alt="Loading.." />
                </div>
                )
            }</>
    )
}

export default ProfileComponent