import React, { useContext, useEffect, useState } from "react";
import "./TimerPage.scss";
import { ThemeContext } from "../../context";
import { MainTimerDiv } from "../../components/Timer/styledComponents";
import Focus from "../../assets/images/timer/focus.svg";
import TopBar from "../../components/Timer/Top Bar/TopBar";
import TimerSection from "../../components/Timer/Timer Section/TimerSection";
import BottomaBar from "../../components/Timer/Bottom Bar/BottomBar";
import { generateScramble } from "../../utils/functions/scrambleFunctions";
import { useIsMobile } from "../../utils/functions/CustomHooks";
import { ReserveContext } from "../../App";
import MainSolveModal from "../../components/Modals/SolveModal/MainSolveModal";
import MainDeleteSolveModal from "../../components/Modals/DeleteSolveModal/MainDeleteSolveModal";
import PhoneNumber from "../../components/PhoneNumberModal/PhoneNumber";
import { useAuthState } from "react-firebase-hooks/auth";
import { getAuth } from "firebase/auth";

const TimerPage = ({ focusMode, setFocusMode }) => {
  window.main();
  const [cubeType, setCubeType] = useState(localStorage.getItem("cubeType") || "222");
  const isMobile = useIsMobile();
  const { isFocus, overlay, Setoverlay, matrixDropdown, inspRunningRef, guide } = useContext(ReserveContext)
  const { allStyles } = useContext(ThemeContext);
  const [disableCubeType, setDisableCubeType] = useState(false);
  const [load, setLoad] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [scramble, setScramble] = useState("Loading.....");
  const [svg, setSvg] = useState("load");
  const [sessionData, setSessionData] = useState({});
  const [isTimer, setIsTimer] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [lockScramble, setLockScramble] = useState(false);
  const [lockIcon, setLockIcon] = useState(false);
  let sessions = useContext(ReserveContext).reserve.sessions
  var solvesData = useContext(ReserveContext).reserve.solves;
  let currentSession = localStorage.getItem('current_session');
  currentSession = JSON.parse(currentSession)
  let currentSessionIdRead = currentSession?.sessionId;
  const [currentSessionId, setCurrentSessionId] = useState(currentSessionIdRead ? currentSessionIdRead : sessions ? sessions[0] : '');
  let currentSolvesData = solvesData ? solvesData?.filter((item) => item?.sessionId === currentSessionId && item.cubeType === cubeType) : null
  const [solves, setSolves] = useState(currentSolvesData);
  const [isInspection, setIsInspection] = useState(inspRunningRef.current);
  

  useEffect(() => {
    let check = localStorage.getItem("lockScramble");
    let checkCubeType = localStorage.getItem("lockCubeType");
    let svgType = localStorage.getItem('svg')
    let scr;
    if (check) {
      if (cubeType == checkCubeType) {
        setLockIcon(true);
        scr = check;
        setSvg(svgType);
      }
      else {
        setLockIcon(false)
        if (cubeType == "minx") {
          scr = generateScramble("mminx");
        } else {
          scr = generateScramble(cubeType);
        }
      }

    }
    else {
      if (cubeType == "minx") {
        scr = generateScramble("mminx");
      } else {
        scr = generateScramble(cubeType);
      }
    }
    setScramble(scr);
    setLoad(true);
  }, [cubeType, refresh]);

  const handleCubeType = (type) => {
    setCubeType(type);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  const [isPhone, setIsPhone] = useState(true);
  const [user, loading] = useAuthState(getAuth());
  // console.log("user: ", user, isPhone)
  useEffect(() => {
    if(user && !user.phoneNumber) {
      setIsPhone(true)
    }
    if(user && user.phoneNumber) {
      setIsPhone(false)
    }
  }, []);
  
  // console.log("isPhone: ", isPhone, guide)
  // if(!guide) {
  //   return (<PhoneNumber isPhone={true} />)  
  // }

  return (
    <>
      {
        isMobile && (isOpen || matrixDropdown) && <div className="no-selector"></div>
      }
      {
      isPhone &&
       !guide &&
      (localStorage.getItem('verify_modal') > 0) &&
      <PhoneNumber 
        isPhone={true} 
      />
      }
      <MainDeleteSolveModal allStyles={allStyles} setSolves={setSolves} />

      <MainSolveModal allStyles={allStyles} setSolves={setSolves} />

      <div theme={allStyles} className={`timer-main${isMobile ? '-mobile' : ""} ${isFocus ? 'timer-position' : ''} no-select`} style={{marginTop: `${isMobile && !isFocus ? '5rem' : ''}`}}>
        <TopBar
          sessions={sessions}
          sessionData={sessionData}
          setSessionData={setSessionData}
          Focus={Focus}
          allStyles={allStyles}
          cubeType={cubeType}
          handleCubeType={handleCubeType}
          setLoad={setLoad}
          disableCubeType={disableCubeType}
          setDisableCubeType={setDisableCubeType}
          focusMode={focusMode}
          setFocusMode={setFocusMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          isTimer={isTimer}
          isInspection={isInspection}
        />

        <TimerSection
          scramble={scramble}
          allStyles={allStyles}
          setRefresh={setRefresh}
          refresh={refresh}
          cubeType={cubeType}
          svg={svg}
          sessionData={sessionData}
          isTimer={isTimer} setIsTimer={setIsTimer}
          lockScramble={lockScramble} setLockScramble={setLockScramble}
          lockIcon={lockIcon} setLockIcon={setLockIcon}
          overlay={overlay}
          Setoverlay={Setoverlay}
          setIsInspection={setIsInspection}
        />

        { !isMobile && <BottomaBar
          scramble={scramble}
          cubeType={cubeType}
          allStyles={allStyles}
          load={load}
          svg={svg}
          setSvg={setSvg}
          focusMode={focusMode}
          setFocusMode={setFocusMode}
          sessionData={sessionData}
          overlay={overlay}
          Setoverlay={Setoverlay}
          isTimer={isTimer}
        />}
       
      </div>
       { isMobile && (
        <div>
          <BottomaBar
          scramble={scramble}
          cubeType={cubeType}
          allStyles={allStyles}
          load={load}
          svg={svg}
          setSvg={setSvg}
          focusMode={focusMode}
          setFocusMode={setFocusMode}
          sessionData={sessionData}
          overlay={overlay}
          Setoverlay={Setoverlay}
          isTimer={isTimer}
        />
        </div>
       )}
    </>
  );
};

export default TimerPage;
