import React, { useContext, useState, useEffect } from 'react'
import { AuthButton } from './styledComponents';
import { ThemeContext } from '../../../context';
import { getAdditionalUserInfo, signInWithPhoneNumber } from 'firebase/auth';
// import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, RecaptchaVerifier } from 'firebase/auth';


function PhoneAuth() {
    const auth = getAuth();
    const recaptchaVerifier = RecaptchaVerifier;
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [otp, setOtp] = useState('');
  // const [notVerified, setNotVerified] = useState(true);
  const [confirmationResult, setConfirmationResult] = useState(null);
  // const [user, loading] = useAuthState(auth);
  const {allStyles} = useContext(ThemeContext);
   useEffect(() => {

    window.recaptchaVerifier = new recaptchaVerifier('recaptcha-container', {
      size: 'normal',
      // callback: () => {
      //   // reCAPTCHA solved, allow signInWithPhoneNumber
      // }
    },
    auth
    );
    }, []);

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    const appVerifier = window.recaptchaVerifier;
    let phone = '+91' + phoneNumber
    try {
      const confirmation = await signInWithPhoneNumber(
        auth,
        phone,
        appVerifier
      );
      setConfirmationResult(confirmation);
      // setSent(true);
    } catch (error) {
      alert(error.message);
    }
  };

  const confirmCode = async (e) => {
    e.preventDefault();
    try {
      const result = await confirmationResult.confirm(otp);
      // setNotVerified(false);
      const {isNewUser} = getAdditionalUserInfo(result);
      if(isNewUser){
        localStorage.clear();
        localStorage.setItem('isNewUser', 1);
      }
      localStorage.setItem('Uid', result.user.uid)
      localStorage.setItem('email', result.user.email)
      localStorage.setItem('name', result.user.displayName)
      localStorage.setItem('mobile', result.user.phoneNumber)
      localStorage.setItem('verify_modal', 0);

      window.location.reload();
    } catch (error) {
      alert(error.message);
    }
  };
    
  return (
    <>
        <div className="auth-input-button-stack">
            <div className="auth-input-stack">
                {/* <AuthTextDivDim theme={allStyles} className="auth-input-label">
                or try entering your email manually
                </AuthTextDivDim> */}
                <label className="auth-label">Phone Number</label>

                {/* <div className="input-error-div">
                <input
                    type="text"
                    className="auth-input"
                    onChange={(e) => {
                    setPhoneNumber(e.target.value);
                    }}
                    value={phoneNumber}
                    // onKeyDown={handleKeyPress}
                /> */}
                <div style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              height: "21vh",
                              justifyContent: "space-between",
                          }}>
                    {!confirmationResult ? (
                        // <form onSubmit={handlePhoneSubmit}>
                        <>
                        <input
                        className="auth-input"
                            type="tel"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                            placeholder="Phone number"
                            required
                        />
                        {/* <button type="submit">Send OTP</button> */}
                        <div id="recaptcha-container" style={{display: !confirmationResult ? 'block' : 'none' }}></div>
                        </>
                        // </form>
                    ) : (
                        // <form onSubmit={confirmCode}>
                        <>
                        <input
                            type="number"
                            value={otp}
                            className='auth-input'
                            onChange={(e) => setOtp(e.target.value)}
                            placeholder="Enter OTP"
                            required
                        />
                        {/* <button type="submit">Verify OTP</button> */}
                        </>
                        // </form>
                    )}
                </div>
                {/* {
                    (error) && <div style={{ color: `${color}` }}>
                    {message}
                    </div>
                }
                {
                    emailSend && <div className="Success-mess" style={{ color: `${color}` }}>
                    {message}
                    </div>
                } */}

                </div>
            {/* </div> */}

            {/* {btn ?  */}
            <AuthButton 
                theme={allStyles}
                className="auth-button"
                onClick={!confirmationResult ? handlePhoneSubmit : confirmCode}
                // style={{ marginTop: `${error || emailSend ? '0.5rem' : ''}` }}
            >
                {!confirmationResult ? 'Send OTP' : 'Verify OTP'}
            </AuthButton>
            {/* : null} */}
        </div>
    </>
  )
}

export default PhoneAuth