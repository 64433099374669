import React, { useRef, useState, useEffect, useContext, useCallback } from "react";
import "./TimerSection.scss";
import { TextDiv, TimerInput } from "../styledComponents";
import sec8mp3 from "../../../assets/sounds/8sec.mp3";
import sec12mp3 from "../../../assets/sounds/12sec.mp3";
import SendIcon from '@mui/icons-material/Send';
import {
  extractCentiseconds,
  extractMinutes,
  extractSeconds,
} from "./TimerLogic";
import { ReserveContext } from "../../../App";
import { submitSolve } from "../../../apis/solves";
import { useIsMobile } from "../../../utils/functions/CustomHooks";
import { ThemeContext } from "../../../context";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MdLockOpen, MdOutlineLock } from "react-icons/md";
import { LuCopy, LuRefreshCcw } from "react-icons/lu";
const TimerSection = ({
  allStyles,
  scramble,
  refresh,
  setRefresh,
  cubeType,
  svg,
  sessionData,
  isTimer,
  setIsTimer,
  lockScramble,
  setLockScramble,
  lockIcon,
  setLockIcon,
  setIsInspection
}) => {
  const isMobile = useIsMobile();
  const [isSolveSubmitMobile, setSolveSubmitMobile] = useState(false);
  const timerDisplayRef = useRef(false);
  const [displayTime, setDisplayTime] = useState(0);
  const [isCopied, setIsCopied] = useState(false);
  const [scrambleRefresh, setScrambleRefresh] = useState(false);
  const plus2autoRef = useRef(false);
  const [isRequestInProgress, setIsRequestInProgress] = useState(false);
  const {
    scrambleFontSize,
    setScrambleFontSize,
    TimerFontSize,
    setTimerFontSize,
    ManualTime,
    TimerDisplay,
    Inspection,
    VoiceAlert,
    dispCNS,
    hideTimer, setHideTimer,
    solveSubmit, setSolveSubmit, inspIntRef, inspRunningRef, guide
  } = useContext(ReserveContext);

  var updateReserve = useContext(ReserveContext).updateReserve;
  const [inspColor, setInspColor] = useState("white");
  const { themeName } = useContext(ThemeContext);
  const [timerInput, setTimerInput] = useState("");
  var timerInterval;
  const inspColorTimeoutRef = useRef(null);
  const [tI, setTI] = useState(null);
  const [inspCd, setInspCd] = useState(16);
  const [inspDnf, setInspDnf] = useState(false);
  const timerInspectionRef = useRef(false);
  const play8secRef = useRef(null);
  const play12secRef = useRef(null);
  const [scrambleFontSizeComp, setScrambleFontSizeComp] = useState(scrambleFontSize);
  const [timerFont, setTimerFont] = useState(TimerFontSize);
  
  const textRef = useRef(null);

  useEffect(() => {
    setScrambleFontSizeComp(scrambleFontSize);
  }, [scrambleFontSize, setScrambleFontSizeComp, scrambleFontSize, setScrambleFontSize]);

  useEffect(() => {
    setTimerFont(TimerFontSize);
  }, [TimerFontSize, setTimerFont, TimerFontSize, setTimerFontSize]);

  useEffect(() => {
    play8secRef.current = new Audio(sec8mp3);
    play12secRef.current = new Audio(sec12mp3);

    // This function will be called when the component mounts
    const initializeAudio = async () => {
      try {
        // Try to play a silent audio buffer to initiate the audio context
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const buffer = audioContext.createBuffer(1, 1, 22050);
        const source = audioContext.createBufferSource();
        source.buffer = buffer;
        source.connect(audioContext.destination);
        source.start(0);
        await source.startPromiseNode();
        source.stop(0);
      } catch (error) {
        console.error("Failed to initialize audio context:", error);
      }
    };

    initializeAudio();

  }, []);

  const submitSolveData = useCallback(async (time) => {
    if (isRequestInProgress) return;
    setIsRequestInProgress(true);
    try {
      const body = {
        time,
        cubeType,
        scramble,
        notes: " ",
        sessionId: sessionData.sessionId,
        dateTime: new Date().toISOString(),
        plus2auto: plus2autoRef.current,
        dnfAuto: inspDnf,
      };
      setInspDnf(false);
      setSolveSubmit(true);
      const { solve } = await submitSolve(body);
      updateReserve((reserve) => {
        if (!reserve.solves) reserve.solves = [];
        reserve.solves.push(solve);
      });
      plus2autoRef.current = false;
    } catch (error) {
      console.error("Error submitting solve:", error);
    } finally {
      setIsRequestInProgress(false);
    }
  }, [isRequestInProgress, cubeType, scramble, sessionData.sessionId, inspDnf, updateReserve]);

  // const touchUp = () => {

  //   if (isRequestInProgress) {
  //     return; 
  //   }

  //   if (!dispCNS) {
  //     // hide timer when inspection is off
  //     if (TimerDisplay && !Inspection) {
  //       setHideTimer(true)
  //     }

  //     function hideTimerDisplayWithInspection() {
  //       setHideTimer(true)
  //     }

  //     if (TimerDisplay && Inspection) {
  //       hideTimerDisplayWithInspection();

  //     }

  //     setInspColor((prevInspColor) => {
  //       if (inspRunningRef.current && prevInspColor == "red") {
  //         clearTimeout(inspColorTimeoutRef.current);
  //         return "white";
  //       }
  //       if (inspRunningRef.current && prevInspColor == "green") {
  //         clearTimeout(inspColorTimeoutRef.current);
  //         clearInterval(inspIntRef.current);
  //         inspRunningRef.current = false;
  //         let start = Date.now();

  //         setIsTimer(true);
  //         timerInterval = setInterval(() => {
  //           let now = Date.now();
  //           setDisplayTime(now - start);
  //         }, 1);
  //         setTI(timerInterval);
  //         return "white";
  //       }
  //       return "white";
  //     });

  //     if (inspRunningRef.current && !isTimer) {
  //       return;
  //     }

  //     if (isTimer) {
  //       // setScramble(generateScramble(cubeType));
  //       setRefresh(!refresh);

  //       setIsTimer(false);

  //       setDisplayTime((prevDisplayTime) => {
  //         const quickCall = async () => {
  //           setIsRequestInProgress(true); 
  //           let body = {
  //             time: prevDisplayTime,
  //             cubeType: cubeType,
  //             scramble: scramble,
  //             notes: " ",
  //             sessionId: sessionData.sessionId,
  //             dateTime: new Date().toString(),
  //             plus2auto: plus2autoRef.current,
  //             dnfAuto: inspDnf,
  //           };
  //           setInspDnf(false);
  //           setSolveSubmit(true)
  //           let solve = (await submitSolve(body)).solve;
  //           updateReserve((reserve) => {
  //             if (!reserve.solves) reserve.solves = [];
  //             reserve.solves.push(solve);
  //           });
  //           plus2autoRef.current = false;
  //           setIsRequestInProgress(false)
  //         };
  //         quickCall();
  //         return prevDisplayTime; // Return the previous value
  //       });

  //       return;
  //     }

  //     inspRunningRef.current = false;
  //     if (Inspection) {
  //       return;
  //     } else {
  //       let start = Date.now();

  //       setIsTimer(true);
  //       timerInterval = setInterval(() => {
  //         let now = Date.now();
  //         setDisplayTime(now - start);
  //       }, 1);
  //       setTI(timerInterval);
  //     }
  //   }
  // };

  const touchUp = useCallback(() => {
    if(isMobile && ManualTime) {
      return
    }
    if (!dispCNS) {
      if (TimerDisplay) {
        setTimeout(() => setHideTimer(true), 100);
      }

      setInspColor((prevInspColor) => {
        if (inspRunningRef.current) {
          clearTimeout(inspColorTimeoutRef.current);
          if (prevInspColor === "green") {
            clearInterval(inspIntRef.current);
            inspRunningRef.current = false;
            const start = Date.now();
            setIsTimer(true);
            const timerInterval = setInterval(() => {
              setDisplayTime(Date.now() - start);
            }, 1);
            setTI(timerInterval);
          }
          return "white";
        }
        return prevInspColor;
      });

      if (inspRunningRef.current && !isTimer) return;

      if (isTimer) {
        setRefresh(!refresh);
        setIsTimer(false);
        setDisplayTime((prevDisplayTime) => {
          submitSolveData(prevDisplayTime);
          return prevDisplayTime;
        });
        return;
      }

      inspRunningRef.current = false;
      if (!Inspection) {
        const start = Date.now();
        setIsTimer(true);
        const timerInterval = setInterval(() => {
          setDisplayTime(Date.now() - start);
        }, 1);
        setTI(timerInterval);
      }
    }

  }, [dispCNS, TimerDisplay, Inspection, isTimer, refresh, setRefresh, setIsTimer, submitSolveData]);


  // const touchDown = () => {
  //   setHideTimer(false)
  //   setSolveSubmit(false)
  //   if (!dispCNS) {
  //     if (inspRunningRef.current) {
  //       // clearInterval(inspIntRef.current);
  //       // setInspCd(16);
  //       // inspRunningRef.current = false;
  //       setInspColor((prevInspColor) => {
  //         if (prevInspColor == "red") {
  //           return "red";
  //         }
  //         if (prevInspColor == "green") {
  //           return "green";
  //         }
  //         // console.log(prevInspColor);
  //         if (prevInspColor == "white") {
  //           inspColorTimeoutRef.current = setTimeout(() => {
  //             setInspColor("green");
  //           }, 500);
  //           return "red";
  //         }
  //       });
  //       return;
  //     }

  //     if (Inspection && !inspRunningRef.current && !isTimer) {
  //       setInspCd((prevInspCd) => {
  //         inspRunningRef.current = true;
  //         return 0;
  //       });
  //       inspIntRef.current = setInterval(() => {
  //         setInspCd((prevInspCd) => {
  //           if (VoiceAlert) {
  //             if (prevInspCd == 7) {
  //               play8secRef.current.play();
  //             }
  //             if (prevInspCd == 11) {
  //               play12secRef.current.play();
  //             }
  //           }
  //           if (prevInspCd == 14) {
  //             plus2autoRef.current = true;
  //           }
  //           if (prevInspCd == 16) {
  //             clearInterval(inspIntRef.current);
  //             setInspDnf(true);
  //             // inspRunningRef.current = false;
  //             // const quickCall = async () => {
  //             //   let body = {
  //             //     time: 3600000,
  //             //     cubeType: cubeType,
  //             //     scramble: scramble,
  //             //     notes: " ",
  //             //     sessionId: sessionData.sessionId,
  //             //     dateTime: new Date().toString(),
  //             //     dnfAuto: true,
  //             //     plus2auto: plus2autoRef.current,
  //             //   };
  //             //   let solve = (await submitSolve(body)).solve;
  //             //   updateReserve((reserve) => {
  //             //     if (!reserve.solves) reserve.solves = [];
  //             //     reserve.solves.push(solve);
  //             //   });
  //             //   plus2autoRef.current = false;
  //             // };
  //             // quickCall();
  //             return prevInspCd;
  //           }
  //           return prevInspCd + 1;
  //         });
  //       }, 1000);
  //     }

  //     clearInterval(tI);
  //     clearInterval(timerInterval);
  //   }
  // };

  const touchDown = useCallback(() => {
    if(isMobile && ManualTime) {
      return
    }
    setHideTimer(false);
    setSolveSubmit(false);
    if (!dispCNS ) {
      if (inspRunningRef.current) {
        setInspColor((prevInspColor) => {
          if (prevInspColor === "white") {
            inspColorTimeoutRef.current = setTimeout(() => {
              setInspColor("green");
            }, 500);
            return "red";
          }
          return prevInspColor;
        });
        return;
      }

      if (Inspection && !inspRunningRef.current && !isTimer) {
        setInspCd(0);
        inspRunningRef.current = true;
        inspIntRef.current = setInterval(() => {
          setInspCd((prevInspCd) => {
            if (VoiceAlert) {
              if (prevInspCd === 7) play8secRef.current.play();
              if (prevInspCd === 11) play12secRef.current.play();
            }
            if (prevInspCd === 14) plus2autoRef.current = true;
            if (prevInspCd === 16) {
              clearInterval(inspIntRef.current);
              setInspDnf(true);
              return prevInspCd;
            }
            return prevInspCd + 1;
          });
        }, 1000);
      }

      clearInterval(tI);
    }
  }, [dispCNS, Inspection, isTimer, VoiceAlert, tI]);

  // When the key is pressed
  const spacedown = (event) => {
    if (event.code != "Space" || guide) {
      return;
    }

    if (!dispCNS) {
      event.preventDefault();
      touchDown();
    }
  };

  // When the key is released
  const spaceup = (event) => {
    setHideTimer(false);
    if (event.code != "Space" || guide) {
      if (isTimer && !isRequestInProgress) {
        clearInterval(tI);
        clearInterval(timerInterval);
        setRefresh(!refresh);
        setDisplayTime((prevDisplayTime) => {
          const quickCall = async () => {
            setIsRequestInProgress(true); // Set the lock
            try {
              let body = {
                time: prevDisplayTime,
                cubeType: cubeType,
                scramble: scramble,
                notes: " ",
                sessionId: sessionData.sessionId,
                dateTime: new Date().toString(),
                plus2auto: plus2autoRef.current,
                dnfAuto: inspDnf,
              };
              setInspDnf(false);
              let solve = (await submitSolve(body)).solve;
              updateReserve((reserve) => {
                if (!reserve.solves) reserve.solves = [];
                reserve.solves.push(solve);
              });
              plus2autoRef.current = false;
            } catch (error) {
              console.error("Error submitting solve:", error);
            } finally {
              setIsRequestInProgress(false); // Release the lock
            }
          };
          quickCall();
          return prevDisplayTime;
        });
      }
  
      return;
    }
    
    if (!dispCNS) {
      event.preventDefault();
      touchUp();
    }

  };

    useEffect(() => {
      setIsInspection(inspRunningRef.current);
  }, [inspRunningRef.current]);
  

  useEffect(() => {
    if (!ManualTime) {
      window.addEventListener("keydown", spacedown);
      window.addEventListener("keyup", spaceup);
  
      let check = localStorage.getItem("lockScramble");
      if (check) {
        setLockScramble(true);
      }
  
      return () => {
        window.removeEventListener("keydown", spacedown);
        window.removeEventListener("keyup", spaceup);
      };
    }
  }, [guide, refresh, cubeType, sessionData, isTimer, dispCNS, ManualTime, setLockScramble]);

  // copying scramble to clipboard
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(scramble);
      if (!isCopied) {
        setIsCopied(true);
        toast.success("Copied to Clipboard.", { autoClose: 1000, className: "copy-toast" });
        setTimeout(() => setIsCopied(false), 2000);
      }
    } catch (error) {
      toast.error("Failed to copy text to clipboard.", { autoClose: 1000, className: "copy-toast" });
    }
  };

  // Locking scramble in storage
  const LockScrambleInStorage = () => {
    const check = localStorage.getItem("lockScramble");
    const checkCubeTy = localStorage.getItem("lockCubeType");
    if (check) {
      toast.success("Scramble Unlocked!", { autoClose: 1000, className: "copy-toast" });
      if (checkCubeTy === cubeType) {
        localStorage.removeItem("lockScramble");
        localStorage.removeItem("lockCubeType");
        localStorage.removeItem("svg");
        setLockScramble(false);
        setLockIcon(false);
      } else {
        localStorage.setItem("lockScramble", scramble);
        localStorage.setItem("lockCubeType", cubeType);
        localStorage.setItem("svg", svg);
        setLockScramble(true);
        setLockIcon(true);
      }
    } else {
      toast.success("Scramble Locked!", { autoClose: 1000, className: "copy-toast" });
      localStorage.setItem("lockScramble", scramble);
      localStorage.setItem("lockCubeType", cubeType);
      localStorage.setItem("svg", svg);
      setLockScramble(true);
      setLockIcon(true);
    }
  };
  
  // handle key press when manual time input is enabled
  const handleKeyPress = useCallback(async (event) => {
    if ((!isMobile && event?.code === "Enter") || (isMobile && ManualTime && isSolveSubmitMobile)) {
      let inputValue = timerInput.trim().replace(/[^\d]/g, "");
      const inputLength = inputValue.length;
      let totalMilliseconds;

      if (inputLength === 1) {
        totalMilliseconds = parseFloat(`00.0${inputValue}`) * 1000;
      } else if (inputLength === 2) {
        totalMilliseconds = parseFloat(`00.${inputValue}`) * 1000;
      } else if (inputLength === 3) {
        totalMilliseconds = parseFloat(`${inputValue.slice(0, 1)}.${inputValue.slice(1)}`) * 1000;
      } else if (inputLength === 4) {
        totalMilliseconds = parseFloat(`${inputValue.slice(0, 2)}.${inputValue.slice(2)}`) * 1000;
      } else if (inputLength === 5) {
        const [minutes, seconds, milliseconds] = [
          inputValue.slice(0, 1),
          inputValue.slice(1, 3),
          inputValue.slice(3)
        ];
        totalMilliseconds = (parseInt(minutes) * 60 + parseInt(seconds) + parseInt(milliseconds) / 100) * 1000;
      } else if (inputLength === 6) {
        const [minutes, seconds, milliseconds] = [
          inputValue.slice(0, 2),
          inputValue.slice(2, 4),
          inputValue.slice(4)
        ];
        totalMilliseconds = (parseInt(minutes) * 60 + parseInt(seconds) + parseInt(milliseconds) / 100) * 1000;
      } 
      // else if (inputLength === 7 || inputLength === 8) {
      //   const [hours, minutes, seconds, milliseconds] = [
      //     inputValue.slice(0, inputLength - 6),
      //     inputValue.slice(-6, -4),
      //     inputValue.slice(-4, -2),
      //     inputValue.slice(-2)
      //   ];
      //   totalMilliseconds = (parseInt(hours) * 3600 + parseInt(minutes) * 60 + parseInt(seconds) + parseInt(milliseconds) / 100) * 1000;
      // }

      
      setDisplayTime(totalMilliseconds);
      await submitSolveData(totalMilliseconds);
      // setDisplayTime((prevDisplayTime) => {
      //   const quickCall = async () => {
      //     let body = {
      //       time: prevDisplayTime,
      //       cubeType: cubeType,
      //       scramble: scramble,
      //       notes: " ",
      //       sessionId: sessionData.sessionId,
      //       dateTime: new Date().toString(),
      //     };
      //     let solve = (await submitSolve(body)).solve;
      //     updateReserve((reserve) => {
      //       if (!reserve.solves) reserve.solves = [];
      //       reserve.solves.push(solve);
      //     });
      //   };
      //   quickCall();
      //   return prevDisplayTime; // Return the previous value
      // });
      setTimerInput("");
      // isMobile && setSolveSubmitMobile(!isSolveSubmitMobile);
      setRefresh(!refresh);
    }
  }, [timerInput, submitSolveData, setRefresh, refresh, isSolveSubmitMobile]);

  useEffect(() => {
    if(ManualTime && isSolveSubmitMobile && isMobile) {
      handleKeyPress();
      setSolveSubmitMobile(false)
    }
  }, [isSolveSubmitMobile, setSolveSubmitMobile])

  // console.log("status", {isMobile, isSolveSubmitMobile, ManualTime})
  // handle input change for manual time input
  const handleInputChange = (e) => {
    const { value } = e.target;
   
    if(value==="") {
      setTimerInput(value)
      return
    }
    if (value.includes(" ")) {
      toast.error("Spaces are not allowed in the timer input.");
      return;
    }
    
    if ( !/^\d+$/.test(value)) {
      toast.error("Only numbers are allowed in the timer input.");
      return;
    }

    setTimerInput(value);
  };
  const scrambleStrings = ['DR DL UL', 'DR DL', 'DR UL', 'DL UL', 'DR', 'DL', 'UL']

  const checkScrString = (scr, scrString) => {
    return scrString.some(str => scr.endsWith(str));
  };

  const scrString = (scr, scrString) => {
    return scrString.find(str => scr.endsWith(str) || '');
  };

  const removeSubstring = (scr, substr) => {
    const index = scr.lastIndexOf(substr);
    if (index === -1) {
      return scr; // Substring not found, return original string
    }
    return scr.slice(0, index);
  };

  const splitScramble = (value) => {
    // Split on U or U' and include them back in the result
    return value.split(/(U\s|U'\s)/).reduce((result, part) => {
      if (part) {
        result.push(part.trim());
      }
      return result;
    }, []);
  };

  const splitValues = splitScramble(scramble);

  const pairedParts = [];
  if (cubeType == 'minx') {
    
    for (let i = 0; i < splitValues.length; i += 2) {
      pairedParts.push(splitValues.slice(i, i + 2).join(' '));
    }
  }

  const groupValues = (values) => {
    const grouped = [];
    for (let i = 0; i < values.length; i += 2) {
      if (i + 1 < values.length) {
        grouped.push([values[i] , values[i + 1]]);
      } else {
        grouped.push([values[i]]);
      }
    }
    return grouped;
  };

  const groupedValues = groupValues(pairedParts);
  return (
    <>

      {isMobile ? (
        <div
          className="top-touch"
          style={{
            height: `${(isTimer || inspRunningRef.current) ? '70vh' : '55%'}`,
            top: `${(isTimer || inspRunningRef.current) ? '10vh' : '21vh'}`,
            zIndex: (isTimer || inspRunningRef.current) ? '999' : '',
          }}
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
        ></div>
      ) : null}
      <div
        className="timer-section prevent-select"
        style={{ top: isMobile && (cubeType == "minx" || cubeType == "777" || cubeType == "666") ? "20%" : null }}
        id="start-inspection"
      >
        <TextDiv
          theme={allStyles}
          ref={textRef}
          className={
            (isMobile
              ? `timer-scramble-text${cubeType == "444" ? "-444" : ""}${cubeType == "777" ? "-777" : ""
              }${cubeType == "minx" ? "-minx" : ""}${cubeType == "555" ? "-555" : ""
              }${cubeType == "666" ? "-666" : ""}`
              : "timer-scramble-text") + `${(isTimer || inspRunningRef.current) ? " timer-started" : ''}`
          }
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
          style={{ fontSize: cubeType == 'minx' && isMobile && scrambleFontSizeComp > 22 ? `${22}px` : `${scrambleFontSizeComp }px`, lineHeight: "25px", flexDirection: `${cubeType == 'minx' ? 'column' : 'row'}` }}
        >
          {cubeType === 'clock' 
          ? 
          checkScrString(scramble, scrambleStrings) 
          ? 
          scrString(scramble, scrambleStrings) === '' 
          ? scramble 
          : 
          removeSubstring(scramble, scrString(scramble, scrambleStrings)) 
          : scramble 
          
          : 
          cubeType === 'minx' && !isMobile ? 
          <>
          
          {groupedValues.map((group, index) => {
            return (
              <div key={index} className="minx-conatiner" >
                {group.map((value, index) => {
                  return (
                    <div key={index} >
                      {value}
                      {index === group.length - 1 ? <br /> : ' '}
                    </div>
                  )
                })}
              </div>
            )
          })}
{/* {groupedValues[0]}  */}
          </>
           : scramble
          }
        </TextDiv>
        <div className={`scramble-icons ${isTimer || inspRunningRef.current ? " timer-started" : ''}`}>
          {lockIcon && lockScramble ? (
            <MdOutlineLock
              title="Lock"
              onClick={() => {
                LockScrambleInStorage();
              }}
              style={{ color: themeName === "dark" ? "green" : "" }}
              className={
                lockIcon && lockScramble
                  ? "lock"
                  : `${themeName === "light" ? "dropdown-color" : ""} lock-img`
              }
            />
          ) : (
            <MdLockOpen
              title="Lock"
              onClick={() => {
                LockScrambleInStorage();
              }}
              className={
                lockIcon && lockScramble
                  ? "lock"
                  : `${themeName === "light" ? "dropdown-color" : ""} lock-img`
              }
            />
          )}

          <LuCopy
            onClick={copyToClipboard}
            title="Copy"
            className={`${themeName === "light" ? "dropdown-color" : ""
              } lock-img`}
          />

          <LuRefreshCcw
            title="Refresh"
            onClick={() => {
              setRefresh(!refresh);
              if (!scrambleRefresh && lockScramble) {
                setScrambleRefresh(true);
                toast.error("Unlock the Scramble!", { autoClose: 1000, className: "copy-toast" });
                setTimeout(() => {
                  setScrambleRefresh(false);
                }, 2000);
              }
              if (!scrambleRefresh && !lockScramble) {
                setScrambleRefresh(true);
                toast.success("Scramble Refreshed!", { autoClose: 1000, className: "copy-toast" });
                setTimeout(() => {
                  setScrambleRefresh(false);
                }, 2000);
              }
            }}
            className={`${themeName === "light" ? "dropdown-color" : ""
              } lock-img`}
          />
        </div>

        <TextDiv
          theme={allStyles}
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
          ref={timerDisplayRef}
          className={`timer`}
          style={{
            fontSize: `${timerFont}px`,
          }}
          id="start-solving"
        >
          {ManualTime ? (
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <TimerInput
              theme={allStyles}
              type="number"
              value={timerInput}
              className="timer-input-manual"
              onChange={handleInputChange}
              onKeyDown={handleKeyPress}
              style={{
                color: `${themeName === "light" ? "rgba(51, 51, 51, 1)" : ""}`,
              }}
            />
             {isMobile && ManualTime && < SendIcon style={{ color: `${themeName === "light" ? "rgba(51, 51, 51, 1)" : ""}`, paddingLeft: "10px" }}  onClick={(e) => {
                setSolveSubmitMobile(!isSolveSubmitMobile);
                window.scrollTo(0, 0);
              }} />}
            </div>
            
          ) : (
            <>
              {inspRunningRef.current ? (
                <div
                  className={
                    "" +
                    (inspColor == "green" ? "green-text" : "") +
                    (inspColor == "red" ? "red-text" : "")
                  }
                >
                  {inspDnf
                    ? "DNF"
                    : inspCd >= 0 && inspCd <= 14
                      ? inspCd
                      : "+2"}
                </div>
              ) : (
                hideTimer == true && solveSubmit == false ?
                  <TextDiv
                    className="timer-solve-text"
                    theme={allStyles}>Solve</TextDiv> :
                  <>
                    {extractMinutes(displayTime)}
                    {extractSeconds(displayTime)}
                    {extractCentiseconds(displayTime)}
                  </>
              )}
            </>
          )}
        </TextDiv>

        <TextDiv
          theme={allStyles}
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
          ref={timerInspectionRef}
          className={`timer hidden`}
          style={{
            fontSize: `${TimerFontSize}px`,
            marginTop: "1rem",
            padding: "2rem",
          }}
          id="start-solving"
        >
          <>
            <div
              className={
                "" +
                (inspColor == "green" ? "green-text" : "") +
                (inspColor == "red" ? "red-text" : "")
              }
            >
              {inspDnf ? "DNF" : inspCd >= 0 && inspCd <= 14 ? inspCd : "+2"}
            </div>
          </>
        </TextDiv>
      </div>
      {/* {isMobile ? (
        <div
          className="bot-touch"
          onTouchStart={touchDown}
          onTouchEnd={touchUp}
        ></div>
      ) : null} */}
    </>
  );
};

export default TimerSection;
